import axios from 'axios';

const channelsWithPolls = {
  testchannel: 'fanfestr2',
  testchannel11124: 'fanfestr2',
  '49ers': 'fanfest-49ers',
  premierleague: 'fanfestr2',
  millonarios: 'fanfestr2',
  everton: 'fanfestr2',
  avfc: 'fanfest-avfc',
  cpfc: 'fanfest-cpfc',
  lufc: 'fanfestr2',
  acmilan: 'fanfest-acmilan',
  spurs: 'fanfest-spurs',
  mancity: 'fanfest-mancity',
  mancitysocios: 'fanfest-mancitysocios',
  psgsocios: 'fanfest-psgsocios',
  fff: 'fanfest-fff',
  psg: 'fanfest-psg'
};

async function initPolls(channelName, userName, userID) {
  if (!window.SportBuff) {
    // console.error('Polls SDK not loaded');
    window.onSportBuffReady = initPolls;
    return;
  }

  /* if (!userName || !userID) {
    console.error('Polls not supported for anonymous (excl. G+) users');
    return;
  } */

  if (!channelsWithPolls[channelName]) {
    console.error('Polls not supported on channel');
    return;
  }

  const container = document.getElementById('contentArea');
  if (!container) {
    console.error('SportBuff container not found');
    return;
  }

  console.log(
    `Initializing Polls for channel ${channelName} and user ${userName} (${userID})`
  );

  // get token from backend
  let token;
  const options = {
    method: 'POST',
    url: 'https://live.fanfest.io/.netlify/functions/sportbuff',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      channelName: channelName,
      userName: userName || 'Anonymous Fan',
      userID: userID || Math.floor(Math.random() * 10000000)
    }
  };
  try {
    const { data } = await axios.request(options);
    token = data.refreshToken;
    console.log('poll token');
    console.log(data);
  } catch (error) {
    console.error(error);
  }

  let sportBuffContainer;

  const appendContainer = (containerToAppend) => {
    sportBuffContainer = containerToAppend;
    container.appendChild(containerToAppend);

    // adding code for removing reaction button and scoreboard if in Simulcast
    if (window.__IS_SIMULFEST__) {
      const removeButtonSheet = new CSSStyleSheet();
      removeButtonSheet.replaceSync(`
          button.bg-button-reaction-bg {
              display: none !important;
          }
      `);

      const removeScoreboardSheet = new CSSStyleSheet();
      removeScoreboardSheet.replaceSync(`
          div[data-testid="floating-panel"] {
              display: none !important;
          }
      `);

      // Combine the existing sheets and new one
      sportBuffContainer.shadowRoot.adoptedStyleSheets = [removeButtonSheet, removeScoreboardSheet];
    }
  };
  const destroyContainer = () => {
    if (!sportBuffContainer) return;
    container.removeChild(sportBuffContainer);
  };
  const handleSignUp = () => {
    // Your custom sign up process can start here.
    // Once it finishes, you can call our signUserIn
    // function providing a correct Token
    console.log(`Signing in user to Poll service with token ${token}`);
    window.widget.signUserIn(token);
  };

  // Storing the SportBuff SDK into the window object can be handy for future reference
  window.widget = await window?.SportBuff.init({
    clientName: 'fanfestio',
    player: 'custom-functions',
    streamSourceId: channelsWithPolls[channelName],
    anonymousLogin: true,
    appendContainer: appendContainer,
    destroyContainer: destroyContainer,
    token: token,
    onSignUp: handleSignUp
  });
}

export default initPolls;
